<template>
  <div class="page">
    <search :isSwipe="2" />
    <!-- 历史搜索 -->
    <div class="search-box" v-if="historyList?.length">
      <div class="box-header">
        <div class="title">{{ $t('历史搜索') }}</div>
        <img v-if="!isDel" @click="handleisDel" class="edit-img" src="@/assets/image/search/edit@2x.png">
        <div v-else class="box-header-right">
          <div class="allDel" @click="handleAlldel">{{ $t('全部删除') }}</div>
          <div class="line"></div>
          <div class="finish" @click="handleFinish">{{ $t('完成') }}</div>
        </div>
      </div>
      <div class="box-main">
        <div class="history-item" @click.stop="handeleSearchFor(item)" v-for="(item,idx) in historyList" :key="idx">
          {{ item }}
          <van-icon v-if="isDel" name="cross" color="#999999" @click.stop="handleDel(idx)" />
        </div>
      </div>
    </div>
    <!-- 热门搜索 -->
    <div class="search-box" v-if="hotlist?.length">
      <div class="box-header">
        <div class="title">{{ $t('热门搜索') }}</div>
      </div>
      <div class="box-main">
        <div class="history-item" v-for="(item,idx) in hotlist" :key="idx" @click="handleLink(item)">
          {{ item.keyWord }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import search from "@/components/search/index"
import Config from "@/utils/config.js"
import { getToken, rmoveToken } from "@/utils/token"
import Mixins from "@/utils/mixins"
import MyStorage from "@/utils/storage"
import { gethotWord, getProductList } from "@/api/search"
const history_records = new MyStorage("history_records")
export default {
  mixins: [Mixins],
  components: {
    search,
  },
  data () {
    return {
      islogin: null,
      imgUrl: Config.imgUrl,
      defaultImg: require('@/assets/image/e-Rith.png'),
      isDel: false,
      historyList: [],
      hotlist: []
    }
  },
  mounted () {
    this.historyList = JSON.parse(history_records.get()) || []
    // this.islogin = !getToken()
    this.getData()
  },
  methods: {
    handleisDel () {
      this.isDel = true
    },
    // 全部删除
    handleAlldel () {
      this.isDel = false
      this.historyList = []
      history_records.remove()
    },
    // 确定清除缓存
    handleFinish () {
      this.isDel = false
      history_records.set(JSON.stringify(this.historyList))
    },
    // 单独删除
    handleDel (idx) {
      this.historyList.splice(idx, 1)
    },
    // 热门跳转
    async handleLink (data) {
      if (data.link) {
        const isFontIcon = data?.link.substring(0, 1) == "/" ? true : false
        this.$router.push(`${!isFontIcon ? '/' : ''}${data?.link}`)
        return
      }
      await this.handeleSearchFor(data.keyWord)
    },
    // 点击历史跳转搜索
    handeleSearchFor (data) {
      if (this.isDel) return
      const keyWord = data.trim()
      if (keyWord) {
        const list = JSON.parse(history_records.get()) || []
        list.unshift(keyWord)
        const uniqueArr = list.filter((item, index) => list.indexOf(item) === index)
        history_records.set(JSON.stringify(uniqueArr))
        this.$router.push(`/productList/${keyWord}`)
      }
    },
    async getData () {
      const res = await gethotWord()
      if (res.resultID === 1200) {
        this.hotlist = res.data
      }
    }
  },
  beforeDestroy () {

  }

}
</script>

<style lang="scss" scoped>
.page {
  background-color: #ffffff;
  height: 100vh;
}

.search-box {
  width: 343px;
  margin: 20px auto 0;

  .box-header {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0;

    .title {
      font-family: OPPOSans B, OPPOSans B;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 24px;
    }

    .edit-img {
      width: 20px;
      height: 20px
    }

    .box-header-right {
      display: flex;
      align-items: center;

      .allDel {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 22px;
      }

      .line {
        width: 1px;
        height: 14px;
        background-color: #EBEBEB;
        margin: 0 8px;
      }

      .finish {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #1A1A1A;
        line-height: 22px;
      }
    }
  }

  .box-main {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .history-item {
      padding: 4px 12px;
      margin-right: 8px;
      margin-bottom: 8px;
      box-sizing: border-box;
      background: #F8F8F8;
      border-radius: 16px 16px 16px 16px;
    }
  }
}
</style>